<template>
    <v-container fluid class="container--fluid fill-height text-left pa-0 backcolor" style="width: 100%">
        <v-row>
            <v-toolbar
                dark
                flat
                height="140px">
                <template v-slot:extension>
                    <v-tabs
                        v-model="tabs"
                        centered
                    >
                        <v-tab
                            v-for="(tab, index) in tabTitles"
                            :key="index">
                            {{ tab }}
                        </v-tab>
                    </v-tabs>
                </template>
            </v-toolbar>

            <v-container>
                <v-row align="start" justify="center">
                    <v-col cols="12" md="10">
                        <v-tabs-items v-model="tabs">
                            <v-tab-item>
                                <v-card flat class="mx-1 my-1 mx-md-16 my-md-10">
                                    <v-card-title class="text-h4">
                                        SOFOM
                                    </v-card-title>
                                    <v-card-text>
                                        <p class="mb-0 font-weight-medium">
                                            <b>SOFOM</b>, participación en toda la cadena de valor, donde el inversionista participará con
                                            una parte de su inversión en el fondeo de la SOFOM, socia de PEB que estará dedicada a
                                            dar crédito directo a los clientes finales para compra de las propiedades desarrolladas,
                                            así como crédito para construcción de casas de campo en el Valle de Guadalupe y al sector
                                            del turismo médico buscando obtener tasas de retorno de dos dígitos al año.
                                        </p>
                                    </v-card-text>
                                    <v-card-actions class="justify-start">
                                        <v-btn link text depressed color="blue" small :to="{path: '/sofom'}">
                                            Conoce más >
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-tab-item>
                            <v-tab-item>
                                <v-card flat class="mx-1 my-1 mx-md-16 my-md-10">
                                    <v-card-title class="text-h4">
                                        DESARROLLOS INMOBILIARIOS
                                    </v-card-title>
                                    <v-card-text>
                                        <p>
                                            Maecenas ullamcorper, dui et placerat feugiat, eros pede varius nisi, condimentum viverra felis nunc et lorem. Sed hendrerit. Maecenas malesuada. Vestibulum ullamcorper mauris at ligula. Proin faucibus arcu quis ante.
                                        </p>

                                        <p class="mb-0">
                                            Etiam vitae tortor. Curabitur ullamcorper ultricies nisi. Sed magna purus, fermentum eu, tincidunt eu, varius ut, felis. Aliquam lobortis. Suspendisse potenti.
                                        </p>
                                    </v-card-text>
                                    <v-card-actions class="justify-start">
                                        <v-btn link text color="blue" small :to="{path: '/projects'}">
                                            Conoce más >
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-tab-item>
                            <v-tab-item>
                                <v-card flat class="mx-1 my-1 mx-md-16 my-md-10">
                                    <v-card-title class="text-h4">
                                        EVENTOS Y ESPECTÁCULOS
                                    </v-card-title>
                                    <v-card-text>
                                        <p class="mb-0 font-weight-medium">
                                            <b>Participación en negocios de alto rendimiento</b>, bajo la <i>expertise</i> empresarial de PEB se
                                            invertirá una parte del portafolio en eventos, espectáculos, giras artísticas y eventos
                                            deportivos con el objetivo de generar un alto valor a las inversiones por su alto
                                            rendimiento en el corto plazo, así también como apoyo al manejo de los flujos de los
                                            inversionistas.
                                        </p>
                                    </v-card-text>
                                    <v-card-actions class="justify-start">
                                        <v-btn link text depressed color="blue" small :to="{path: '/special-projects'}">
                                            Conoce más >
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-col>
                </v-row>
            </v-container>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "BriefcaseComponent",
        data: () => ({
            tabs: null,
            tabTitles: ['SOFOM', 'Desarrollos Inmobiliarios', 'Eventos y Espectáculos']
        }),
    }
</script>

<style scoped>
    .backcolor {
        background-color: #ffffff;
        margin-top: 0;
    }
    /*.v-btn {
        text-transform: none !important;
    }*/
    .my-slider > .v-slide-group__next.theme--light.v-icon {
        color: rgb(234, 10, 10) !important;
        font-size: 24pt !important;
    }
    .div-hover {
        cursor: pointer;
    }
    .card {
        min-height: 150px;
        border-width: 0px;
        /*padding: 5px;*/
        transition: 0.5s ease-out;
    }
    .card .v-image {
        /*margin-bottom: 5px;*/
        transition: 0.75s;
    }
    .card h1 {
        margin-bottom: 10px;
    }
    .v-card--reveal {
        align-items: center;
        bottom: 0;
        align-content: end;
        justify-content: center;
        opacity: .7;
        position: absolute;
        width: 100%;
    }
    .zoom-efect {
        transform: scale(1.1);
    }
    .up {
        transform: translateY(-20px);
        transition: 0.5s ease-out;
    }
    .zoom-img {
        overflow: hidden;
        border-radius: 10px;
    }
    .zoom-img > img {
        object-fit: cover;
        transition: all .3s ease-in-out;
    }
    .zoom-img:hover > img {
        transform: scale(1.05);
    }
    .zoom-image {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        /* VERZÖGERUNG */
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
        overflow: hidden;
    }
    .zoom-image:hover {
        -webkit-transform: scale(1.07);
        -moz-transform: scale(1.07);
        -ms-transform: scale(1.07);
        -o-transform: scale(1.07);
        transform: scale(1.07);
    }
</style>