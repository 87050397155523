<template>
    <briefcase-component/>
</template>

<script>
    import BriefcaseComponent from "@/components/briefcase/BriefcaseComponent";

    export default {
        name: "Briefcase",
        title: "Portafolio | Private",
        components: {BriefcaseComponent}
    }
</script>

<style scoped>

</style>